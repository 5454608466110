<template>
  <scrollable-container>
    <tabset :tabs="tabs" />
    <keep-alive>
      <router-view />
    </keep-alive>
  </scrollable-container>
</template>

<script>
import Tabset from '@/components/common/tabs/tabset.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';

export default {
  components: {
    Tabset,
    ScrollableContainer,
  },
  data: () => ({
    tabs: [
      {
        title: 'Отчет по сотрудникам',
        route: './employeeReport',
      },
      {
        title: 'Отчет по печати сотрудников',
        route: './employee-print-report',
      },
    ],
  }),
};
</script>
